import React, { Component } from "react";
import AOS from "aos";

import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { Container, Row, Col } from "react-bootstrap";
import Whatsapp_icon from "../components/whatsapp_icon";
import Mobile_Navbar from "../components/mobile_navbar";
import '../assets/css/studentJob.css';
import { Helmet } from 'react-helmet';
import { first_img1 } from "./mls_constants";

const stars = (num) => {
  const stars_arr = [];
  for (let i = 0; i < num; i++) {
    stars_arr.push(<span className="star">&#9733;</span>);
  }
  return stars_arr;
};
const comment = (comment, name, stars_arr) => {
  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="Success stories of Algo Ninjas Students & happy Parents Feedback | Algo Ninjas" />
        <meta name="description" content="Algo Ninjas students are consistently attaining remarkable achievements in highly coveted national and international examinations | Algo Ninjas
"/>
        <meta name="keywords" content="Algo Ninjas offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Algo Ninjas are specifically designed to accelerate your child's academic progress, programs, GUARANTEED.| Algo Ninjas
"/>
        <meta name="robots" content="index, follow" />
        <title>Success stories of Algo Ninjas Students & happy Parents Feedback | Algo Ninjas</title>
      </Helmet>
      <div className="comment-box">
        <div className="comment-header">
          <span className="commenter-name">{name}</span>
          <div className="star-rating">{stars(stars_arr)}</div>
        </div>
        <div className="comment-content">
          <p>{comment}</p>
        </div>
      </div>
    </>
  );
};
const StudentJob = () => {
  AOS.init({
    offset: 200,
    duration: 600,
  });
  return (
    <div>
      <Navbar />
      <div className="f-template-contact">
        <img src={first_img1} alt="first Template" />
      </div>
      <div data-aos="flip-up">
        {comment(
          "I enrolled in the software development course at this training center, and I must say it was a transformative experience. The instructors were knowledgeable and supportive, the course material was comprehensive, and the hands-on projects helped me gain practical skills. I landed my dream job right after completing the course, and I owe it all to the excellent training I received here. Thank you so much Algo Ninjas.",
          "Anchal's Parents",
          5
        )}
      </div>
      <div data-aos="flip-up">
        {comment(
          "I am currently pursuing the data science and analytics course, and I am extremely satisfied with the quality of education provided. The curriculum is up-to-date with industry standards, and the instructors are passionate about teaching. The interactive learning environment has boosted my confidence, and I feel well-prepared for a career in data science. I highly recommend this training center to anyone aspiring to enter the field of data science. Thank you so much Algo Ninjas.",
          "Dhaitiri's Mom",
          5
        )}
      </div>
      <div data-aos="flip-up">
        {comment(
          "As a digital marketing professional, I needed to update my skills to stay competitive in the ever-changing digital landscape. I took the digital marketing course here, and it exceeded my expectations. The course covered various aspects of digital marketing, from SEO to social media marketing. The real-world examples and case studies provided valuable insights, and I could immediately apply what I learned to my job. I am grateful for the expertise of the instructors and the practical approach to learning. Thank you so much Algo Ninjas.",
          "Shreya's Parents",
          5
        )}
      </div>
      <div data-aos="flip-up">
        {comment(
          "I am passionate about UI/UX design and wanted to enhance my skills. The UI/UX design course at this training center was exactly what I needed. The course content was comprehensive, covering both the fundamentals and advanced concepts. The instructors encouraged creativity and provided constructive feedback on our design projects. I am now confident in my design abilities and have started freelancing on design projects. This course truly opened doors for me. Thank you so much Algo Ninjas.",
          "Shyam",
          5
        )}
      </div>
      <div data-aos="flip-up">
        {comment(
          "I just wanted to say thank you Ritik for tutoring Sophia. I am very impress with the way you teach and communicate. Keep up the good work.",
          "Sophia's Mom",
          5
        )}
      </div>
      <Container>
        <Row className="rows">
          <div className="exp">
            <Col md="12" xs="12" sm="12">
              <h1 className="student_p">
                "Experience the Joy of Learning: Join Countless Happy Students
                and Parents"
              </h1>
            </Col>
          </div>
        </Row>
      </Container>
      <div className="contact-btn">
        <button>Book 1 Week Free Demo</button>
      </div>
      <Footer />
    </div>
  );
};
export default StudentJob;
