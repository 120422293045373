import React from "react";
import { Carousel } from "react-bootstrap";
import "../assets/css/index.css";
const comment = (comment, name) => {
  return (
    <div className="comment-body">
      <div className="comment-content">{comment}</div>
      <span className="commenter">-{name}</span>
    </div>
  );
};
const CommentCarousel = () => {
  const comments = [
    {
      id: 1,
      comment: comment(
        "I enrolled in the software development course at this training center, and I must say it was a transformative experience. The instructors were knowledgeable and supportive, the course material was comprehensive, and the hands-on projects helped me gain practical skills. I landed my dream job right after completing the course, and I owe it all to the excellent training I received here.",
        "Anchal's Parents"
      ),
    },
    {
      id: 2,
      comment: comment(
        "I am currently pursuing the data science and analytics course, and I am extremely satisfied with the quality of education provided. The curriculum is up-to-date with industry standards, and the instructors are passionate about teaching. The interactive learning environment has boosted my confidence, and I feel well-prepared for a career in data science. I highly recommend this training center to anyone aspiring to enter the field of data science.",
        "Shreya's Parents"
      ),
    },
    {
      id: 3,
      comment: comment(
        "As a digital marketing professional, I needed to update my skills to stay competitive in the ever-changing digital landscape. I took the digital marketing course here, and it exceeded my expectations. The course covered various aspects of digital marketing, from SEO to social media marketing. The real-world examples and case studies provided valuable insights, and I could immediately apply what I learned to my job. I am grateful for the expertise of the instructors and the practical approach to learning.",
        "Dhaitiri's Mom"
      ),
    },
    {
      id: 4,
      comment: comment(
        "I am passionate about UI/UX design and wanted to enhance my skills. The UI/UX design course at this training center was exactly what I needed. The course content was comprehensive, covering both the fundamentals and advanced concepts. The instructors encouraged creativity and provided constructive feedback on our design projects. I am now confident in my design abilities and have started freelancing on design projects. This course truly opened doors for me.",
        "Shyam"
      ),
    },
    {
      id: 5,
      comment: comment(
        "I just wanted to say thank you Ritik for tutoring Sophia. I am very impress with the way you teach and communicate. Keep up the good work.        ",
        "Sophia's Mom"
      ),
    },
    // Add more comments as needed
  ];

  return (
    <div className="carousell">
      <h1>Parents and students love us!</h1>
      <Carousel>
        {comments.map((comment) => (
          <Carousel.Item key={comment.id}>{comment.comment}</Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CommentCarousel;
