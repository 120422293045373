import React, { useEffect, useState } from "react";
import "../assets/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Footer from "../components/footer";
import Courses from "./courses.js";
import slider1 from '../assets/img/home.jpg';
import slider2 from '../assets/img/2.jpeg';
import slider3 from '../assets/img/3.jpeg';
import slider4 from '../assets/img/4.jpeg';
import slider5 from '../assets/img/5.jpeg';
import slider6 from '../assets/img/6.jpeg';
import slider7 from '../assets/img/7.jpeg';


import CommentsSlide from "../components/comments_slide";
import { redirect, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import { ChallengingEnv, Comp_Programs, Confidence_Building, ExpertInstructors, Flexible, Lifelong_Skills, Per_learning, Proven_results, image1, image11, image13, image2, image3 } from "./mls_constants";
function Index() {
  const myInlineStyle = {
    fontSize: "16px",
    color: "blue",
    fontWeight: "bold",
    size: "100px",
    // Add more CSS properties as needed
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Add this line to enable autoplay
    autoplaySpeed: 4000,
  };
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
    });
  }, []);
  const A_benefit = (props) => {
    const labelling = props.label.split(" ");
    return (
      <div data-aos="zoom-in" className="benefit">
        <img src={props.image} alt="Zoom" />
        <p>
          {labelling[0]}
          <br />
          {labelling[1]}
        </p>
      </div>
    );
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactus");
  };
  const FreeClassButton = () => {
    return (
      <div class="free_class">
        <button onClick={handleClick}>Book 1 Week Free Class</button>
      </div>
    );
  };
  const myStyle = {
    display: "flex",
    justifyContent: "center",
  };
  const [youtubeID] = useState("XpScgwRKpgs");
  console.log(`https://youtube.com/embed/${youtubeID}?autoplay=0`);
  return (
    <div>
      <Navbar />
      <Helmet>
        <meta name="google-site-verification" content="bhOOEzJ-8aaGF_FM3O7nKH4GgUpXIhIk0kr_tPC9FTY" />
        <meta name="title" content="Live 1:1 Online Classes for Kids  (Grade 2-12) | Algo Ninjas" />
        <meta name="description" content="Algo Ninjas offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. The programs of Algo Ninjas are specifically designed to accelerate your child's academic progress, programs, GUARANTEED. Book a FREE TRIAL now!" />
        <meta name="keywords" content="Algo Ninjas, MLS, Algo Ninjas offers Live, Online Math, English, Science, Coding, SAT, ACT, AP, SSAT, STAAR, and AMC classes for students in grades 2-12. These programs are specifically designed to accelerate your child's academic progress, programs, GUARANTEED." />
        <title>Skill Development Training & Internship Institute in Jaipur | Algo Ninjas</title>
      </Helmet>
      <div className="Slideshow">
        <Slider {...settings}>
          <div className="f-template" style={myInlineStyle}>
            <img src={slider1} alt="Algo Ninjas - Math, English, Science & Coding - United States" title="Algo Ninjas - Math, English, Science & Coding - United States" />
          </div>
          <div className="f-template">
            <img src={slider2} alt="Algo Ninjas - US Curriculum - United States" title="Algo Ninjas - US Curriculum - United States" />
          </div>
          <div className="f-template">
            <img src={slider3} alt="Algo Ninjas - United States" title="Algo Ninjas - United States" />
          </div>
          <div className="f-template">
            <img src={slider4} alt="Algo Ninjas - SAT, PSAT, ACT Exam - United States" title="Algo Ninjas - SAT, PSAT, ACT Exam - United States" />
          </div>
          <div className="f-template">
            <img src={slider5} alt="Algo Ninjas - Pre Algebra, Geometry, Algebra 1 & 2, Pre Calculus, Calculus, ELA, Science - United States" title="Algo Ninjas - Pre Algebra, Geometry, Algebra 1 & 2, Pre Calculus, Calculus, ELA, Science - United States" />
          </div>
          <div className="f-template">
            <img src={slider6} alt="Algo Ninjas - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" title="Algo Ninjas - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" />
          </div>
          <div className="f-template">
            <img src={slider7} alt="Algo Ninjas - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" title="Algo Ninjas - Physics, Pre Calculus, Economics, Statistics, Psychology, Computer Science, Chemistry - United States" />
          </div>
        </Slider>
      </div>
      <br />
      <br />
      <div className="find-live">
        <div className="brfdesc">
          <a href="#">
            Algo Ninjas offers Live, Online Data Science & Analytics , Software & Programming ,
            Digital Marketing  ,STEM ,Design ,Stock Crypto & Blockchain classes for students and working professionals.
            These programs are specifically designed to accelerate your career.
          </a>
        </div>
        <div className="width-line-space">
          <div className="yellowBtn">&nbsp;</div>
        </div>
        <br />
        <div className="midBanner">
          {/* <h4>Our Programs For 2-12</h4> */}
        </div>
      </div>
      <div style={myStyle}>
        <FreeClassButton />
      </div>
      <div className="boxes">
        <div className="box">
          <h2>Data Science & Analytics Courses</h2>
          <p>Algo Ninjas provide unique data science and analytics courses at your center, focusing on statistical analysis, machine learning, data visualization, and tools like Python, R, and SQL. Equip students with practical skills to extract insights, make data-driven decisions, and thrive in the evolving field of data science.</p>
        </div>
        <div className="box">
          <h2>Software & Programming Courses</h2>
          <p>Algo Ninjas offer covering programming fundamentals, languages (Python, Java, JavaScript, etc.), data structures, web development, databases, version control, debugging, ethics, and hands-on projects for practical experience. Emphasize real-world applications and industry best practices.</p>
        </div>
        <div className="box">
          <h2>Digital Marketing  Courses</h2>
          <p>
Deliver impactful digital marketing courses, covering SEO, social media marketing, content strategy, PPC advertising, and analytics. Empower students with the skills to drive online presence, engage audiences, and optimize marketing campaigns effectively.</p>
        </div>
        <div className="box">
          <h2>STEM Courses</h2>
          <p>Through Algo Ninjas interactive curriculum,Inspire with dynamic STEM courses, blending science, technology, engineering, and mathematics, fostering problem-solving skills and innovation. Encourage hands-on learning, sparking curiosity and preparing students for diverse careers in the ever-evolving fields of STEM.</p>
        </div>
        <div className="box">
          <h2>Design Courses</h2>
          <p>Elevate creativity with design courses, exploring graphic design, UX/UI, digital art, and branding. Cultivate visual storytelling skills and empower students to craft compelling, user-centered experiences in the ever-evolving design landscape.</p>
        </div>
        <div className="box">
          <h2>Stock Crypto & Blockchain Courses</h2>
          <p>Delve into the world of finance with stock, crypto, and blockchain courses, equipping students with trading strategies, market analysis, and blockchain technology insights. Empower them to navigate the complexities of financial markets and emerging digital currencies with confidence.</p>
        </div>
      </div>
      <div className="features">
        <div className="features-h">Main Features</div>

        <div className="features-sub">
          <div className="features-img" data-aos="fade-right">
            <img src={image13} alt="features img" />
          </div>
          <div className="features-content">
            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image1} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">1:1 Dedicated Classes</a>
              </div>
            </div>

            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image2} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">Lowest Class $ per cost</a>
              </div>
            </div>

            <div className="features-content-2" data-aos="fade-left">
              <div className="features-content-img">
                <img src={image3} alt="img" />
              </div>
              <div className="features-content-text">
                <a href="#">Master Teachers </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="popular">
        <div className="popular-head">
          <div className="popular-h">Popular Courses</div>
        </div>
        <Courses />
      </div>
      <div style={myStyle}>
        <FreeClassButton />
      </div>

      <div className="brand-moto">
        <div className="brand-moto-message">
          <h2>Why the Algo Ninjas?</h2>
          <p>
            Algo Ninjas: Where Personalized Learning Meets Excellence! Our
            highly qualified teachers use expertly crafted curricula tailored to
            individual students and working professionals, ensuring a perfect fit for their needs. With
            flexible online programs, your child will flourish academically and
            carry that success beyond the classroom.
          </p>
        </div>

        <div>
      <iframe
          poster={image11}
          className="YoutubeBox"
          src={`https://www.youtube.com/embed/${youtubeID}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          crossOrigin="anonymous"
        ></iframe>
      </div>
      </div>
      
      <div className="benefits-container">
      <div className="brand-moto">
        <div className="brand-moto-message" style={{width:"65%"}}>
          <h2>Our Benefits</h2>
          <p>
          Benefits of Algo Ninjas Online Training for Grades(2-12) | Algo Ninjas.
          </p>
        </div>
          
        </div>
      

        <div className="benefits">
          <A_benefit
            className="bnfts"
            image={ExpertInstructors}
            label="Expert Instructors"
          />
          <A_benefit
            className="bnfts"
            image={Per_learning}
            label="Personalized Learning"
          />
          <A_benefit
            className="bnfts"
            image={Comp_Programs}
            label="Comprehensive Programs"
          />
          <A_benefit
            className="bnfts"
            image={Proven_results}
            label="Proven Results"
          />
          <A_benefit
            className="bnfts"
            image={Flexible}
            label="Flexible Scheduling"
          />
          <A_benefit
            className="bnfts"
            image={ChallengingEnv}
            label="Challenging Enviornment"
          />
          <A_benefit
            className="bnfts"
            image={Confidence_Building}
            label="Confidence Building"
          />
          <A_benefit
            className="bnfts"
            image={Lifelong_Skills}
            label="Lifelong Skills"
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="parents-love">
        <CommentsSlide />
      </div>
      <br />
      <br />
      <br />
      <div style={myStyle}>
        <FreeClassButton />
      </div>
      <h2 className="acc-section">
        Common Faqs </h2>
      <Container id="accord-home" fluid={false} style={{ "marginTop": "5%" }}>

        <Row>
          <Col md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="acc-header">. What courses do you offer at your training center?</Accordion.Header>
                <Accordion.Body>
                At our Algo Ninjas Training center, we offer a wide range of courses including software & programming, data science & analytics, digital marketing, STEM courses, UI/UX design, and stock/crypto/blockchain courses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="acc-header"> Who can enroll in your courses?</Accordion.Header>
                <Accordion.Body>
                  Algo Ninjas offers  courses are open to anyone interested in learning, regardless of their background or experience. Whether you are a beginner or an experienced professional looking to enhance your skills, our courses cater to all levels of expertise.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header className="acc-header" >Could you provide us with the contact information to get course details of Algo Ninjas?</Accordion.Header>
                <Accordion.Body>
                 You can contact Algo Ninjas by 
                 
                 <li>phone at <a href="#">+91 9649549754</a> or via WhatsApp at the same number.</li> 
                  <li>Initiate a WhatsApp chat through this link: <a href="https://wa.me/message/XMS5KMWBGQZLG1">WhatsApp</a></li>
                 <li>If you prefer, you can also use the Contact Us form on the Algo Ninjas website: <a href="https://mlsclasses.com/contactus">contact Algo Ninjas</a></li> 
                 <li>Additionally, you can reach out to us through email at <a href="mailto:admission@mlsclasses.com">admission@mlsclasses.com</a> or <a href="mailto:support@mlsclasses.com">support@mlsclasses.com</a> </li> 
                 We're here to assist you!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header className="acc-header">. How do I enroll in a course?</Accordion.Header>
                <Accordion.Body>
                 To enroll in a Algo Ninjas course, simply visit our website and navigate to the course of your choice. Contact US Page fill the details, we will contact you soon. If you need assistance, our support team is always here to help.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header className="acc-header">What qualifications do your instructors have?</Accordion.Header>
                <Accordion.Body>
                At Algo Ninjas, Our instructors are highly qualified professionals with extensive industry experience. They are experts in their respective fields and are dedicated to providing high-quality education to our students.
                </Accordion.Body>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="acc-header">How are the weekly classes scheduled at Algo Ninjas?</Accordion.Header>
                  <Accordion.Body>
                  At Algo Ninjas, we understand that every student's availability is unique. That's why Algo Ninjas tailor the schedule to suit your preferences. Let Algo Ninjas know the days that work best for your child, and Algo Ninjas will create a personalized timetable that aligns perfectly with their routine.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="acc-header">Is there any support available if I face difficulties during the course?</Accordion.Header>
                  <Accordion.Body>
                  Absolutely! We offer dedicated support to our students. If you have any questions, encounter technical issues, or need clarification on course content, you can reach out to our support team or your instructor. We are committed to helping you succeed.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header className="acc-header">How can I contact your training center for further inquiries?</Accordion.Header>
                  <Accordion.Body>
                  Absolutely! Our Algo Ninjas subscription For any further inquiries, you can contact us through our Contact Us page on the website. Alternatively, you can email us at [your email address] or call us at [your phone number]. We're here to assist you.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

      </Container>


        <Footer />
    </div>
  );
}
export default Index;
